import React from 'react'
import Layout from '../../components/layout'
import LightBox from '../../components/lightbox/lightbox';

import order from '../../images/info/applikation/order.jpg'
import taskList from '../../images/info/applikation/tasklistMa.jpg'

import taskListVG from '../../images/info/applikation/tasklistVG.jpg'
import aufgaben from '../../images/info/applikation/aufgabenerfassung.jpg'
import input from '../../images/info/applikation/inputerfassungzielerreichung.jpg'
import pruefen from '../../images/info/applikation/pruefen.jpg'

import taskListHR from '../../images/info/applikation/tasklistHR.jpg'
import vorschlag from '../../images/info/applikation/vorschlag.jpg'
import word from '../../images/info/applikation/word.jpg'
import doppel from '../../images/info/applikation/doppel_bestaetigen.jpg'
import uebersicht from '../../images/info/applikation/uebersicht.jpg'

import funktionen from '../../images/info/applikation/adm_fun.jpg'
import kompetenzen from '../../images/info/applikation/adm_komp.jpg'
import texte from '../../images/info/applikation/adm_text.jpg'

const empImages = [
  {
    index: 0,
    src: order,
    title: 'Bestellen',
    description: 'Mitarbeitende wählen die gewünschte Zeugnisart (Zwischenzeugnis, Schlusszeugnis, Arbeitsbestätigung) aus und überprüfen die automatisch aus dem Personalinformationssystem abgefüllten Daten. Nach Drücken von "Bestellen" wird der Bestellvorgang ausgelöst. ZEP funktioniert mit oder ohne direkte Schnittstelle zu einem HR-System.'
  },
  {
    index: 1,
    src: taskList,
    title: 'Task-Liste',
    description: 'Mitarbeitende sehen hier die offenen Aufgaben ihrer Aufgabenliste.'
  },
]

const vgImages = [
  {
    index: 0,
    src: taskListVG,
    title: 'Task-Liste',
    description: 'Vorgesetzte werden per Mail über neue Einträge in ihrer Aufgabenliste informiert. Dies können neue Zeugnisbestellungen oder offene Aufgaben von bereits gestarteten Zeugnisprozessen sein.'
  },
  {
    index: 1,
    src: aufgaben,
    title: 'Aufgaben erfassen',
    description: 'Die Aufgaben und Spezialaufgaben der Mitarbeitenden werden über diesen Bildschirm erfasst. Funktionsbeschreibungen können automatisch aus dem bestehenden HR-System eingefügt werden oder manuell ausgefüllt werden.'
  },
  {
    index: 2,
    src: input,
    title: 'Bewertung',
    description: 'Die Vorgesetzten bewerten die Mitarbeitenden in verschiedenen Kompetenzgruppen. Kompetenzgruppen sind durch die Personalabteilung definierbar. ZEP wird mit einem umfassenden Standard von hinterlegten Textbausteinen ausgeliefert.'
  },
  {
    index: 3,
    src: pruefen,
    title: 'Gut zum Druck',
    description: 'Das "Gut zum Druck" wird den Vorgesetzten zur Überprüfung elektronisch zugestellt.'
  },
]

const hrImages = [
  {
    index: 0,
    src: taskListHR,
    title: 'Task-Liste',
    description: 'Die Personalabteilung erhält alle Zeugnisbestellungen in eine gemeinsame Aufgabenliste'
  },
  {
    index: 1,
    src: vorschlag,
    title: 'Zeugnisvorschlag erstellen',
    description: 'Die Personalabteilung kontrolliert den generierten Zeugnistext. Sie ändert und ergänzt den Zeugnistext, sodass er rechts- und firmenkonform ist.'
  },
  {
    index: 2,
    src: word,
    title: 'Zeugnis',
    description: 'Das Zeugnisdokument kann als Worddokument generiert werden und nach Bedarf ausgedruckt werden.'
  },
  {
    index: 3,
    src: doppel,
    title: 'Zeugnisdoppel bestätigen',
    description: 'Zur Sicherstellung komplettierter Personaldossiers kann der Eingang unterzeichneter Zeugnisdoppel als Prozesschritt definiert werden. Die Personalabteilung löst in diesem Fall die Eingangsbestätigung aus.'
  },
  {
    index: 4,
    src: uebersicht,
    title: 'Zeugnsiübersischt',
    description: 'Die Zeugnsiübersischt listet ZEP-Benutzern jene Zeugnisdokumente auf, bei deren Entstehung sie beteiligt waren. Mitarbeitende sehen eigene Zeugnisse, Vorgesetzte sehen eigene Zeugnisse und Zeugnisse ihrer Mitarbeitenden. Detailansicht ist möglich.'
  },
]

const admImages = [
  {
    index: 0,
    src: kompetenzen,
    title: 'Kompetenzen verwalten',
    description: 'ZEP Admin: Die Personalabteilung kann die zu bewertenden Kompetenzen verwalten. Zep wird standardmässig mit 50 vordefinierten Kompetenzen ausgeliefert.'
  },
  {
    index: 1,
    src: texte,
    title: 'Zeugnistexte verwalten',
    description: 'ZEP Admin: Einer Kompetenz können mehrere Textblöcke hinterlegt werden. Die Personalabteilung verwaltet die Textblöcke. ZEP wird standardmässig mit einem kompletten Satz von Kompetenzen mit hinterlegten Zeugnistexten ausgeliefert.'
  },
  {
    index: 2,
    src: funktionen,
    title: 'Vorlagen verwalten',
    description: ' ZEP Admin: Um Vorgesetzte zu entlasten und einheitliche Funktionsbeschreibungen zu gewährleisten, können Funktionen inklusive detaillierter Tätigkeitsbeschreibung erfasst werden.'
  },
]


class Applikation extends React.Component {

  render() {
    return (
      <Layout>
        <section id="news" className="section-wrapper">
          <header className="section-header">
            <h3>Die Applikation</h3>
            <h5>Wie funktioniert die Applikation aus Sicht...</h5>
          </header>
          <br />
          <div style={{ paddingBottom: '50px' }}>
            <h3 className="underlined-title">...der Mitarbeitenden</h3>
            <p>Im ZEP Standard wird das Bestellen eines Zeugnisses durch die Mitarbeitenden ausgelöst.</p>
            <LightBox images={empImages} thumbnailHeight={90} />
          </div>
          <div style={{ paddingBottom: '50px' }}>
            <h3 className="underlined-title">...der Vorgesetzten</h3>
            <p>
              Die zentrale Aufgabe des Zeugnisinputs mit den Bereichen Aufgaben erfassen und Kompetenzen bewerten, übernehmen die Vorgesetzten.
              Damit aufwändige Formulierungsarbeit wegfällt, übernimmt ZEP mit über 700 mitgelieferten Textbausteinen (in 4 Sprachen) diese
              zeitintensive Arbeit für die Vorgesetzten.
            </p>
            <LightBox images={vgImages} thumbnailHeight={90} />
          </div>
          <div>
            <h3 className="underlined-title">...der Personalabteilung</h3>
            <p>
              Die Personalabteilung ist Koordinations- und Prüfstelle für den Zeugnisprozess. Sie hat zu jeder Zeit firmenweiten Überblick über
              bestellte Zeugnisse und deren Prozessfortschritt. Sie überprüft die Qualität der individuellen Texte, historisiert erstellte Zeugnisse, etc.
              <br />
              Zudem steht der Personalabteilung der Bereich ZEP Administration zur Verfügung. Hier werden die Textbausteine unterhalten, Kompetenzen verwaltet etc.
            </p>
            <LightBox images={hrImages} thumbnailHeight={90} />
            <br />
            <LightBox images={admImages} thumbnailHeight={90} />
          </div>
        </section>

      </Layout >
    )
  }
}

export default Applikation
